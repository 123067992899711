import React from 'react';
import './App.css';
import telegram from './assets/telegram.png';
import x from './assets/x.png'


function App() {
  return (
    <div className="App">
      <div className="content">
        <header className="header">
          <div className='header-content'>
            <div>DeadCoin</div>
            <div className='header-right'>
              <a className='share-telegram' href="https://t.me/theDeadCoin" target="_blank" rel="noreferrer"><img className="share-image" src={telegram} alt="" /></a>
              <a href="https://x.com/DeadCoinOnTon" target="_blank" rel="noreferrer"><img className="share-image" src={x} alt="" /></a>
            </div>
          </div>
        </header>
        <main>
          <div className="main-content">
            <div className="main-title">
            Blast off with<br/> DeadCoin
            </div>
            <div className="main-desc">
            Blast the vault！earn more coins！
              <a href="https://t.me/theDeadCoin_bot" className="main-join">Launch DeadCoin</a>
            </div>
          </div>
        </main>
        <footer className="footer">© 2024 All rights reserved.</footer>
      </div>
    </div>
  );
}

export default App;
